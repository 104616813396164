import React, { Fragment, useState } from 'react';
import {
  Button,
  Grid,
  Link,
  MenuItem,
  Box,
  Stack,
  Alert,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import _, { isFunction } from 'lodash';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  IFilterOption,
  IFilterDatasourceOption,
  IOptionItem,
} from '../../common/interfaces';

import SimpleSelect from './SimpleSelect';
import TextInputFilter from '../form/filters/TextInputFilter';
import SimpleSelectFilter from '../form/filters/SimpleSelectFilter';
import MultiSelectFilter from '../form/filters/MultiSelectFilter';
import DateSelectFilter from '../form/filters/DateSelectFilter';
import CheckboxFilter from '../form/filters/CheckboxFilter';
import { FilterOptionTypes } from '../../common/enums';
import DataSourceSelector from '../form/DataSourceSelector';

type DatasourceError = {
  filterName: string;
  hasError: boolean;
};

type FilterProps = {
  filterOptionList: IFilterOption[];
  filterSelectedList: IFilterOption[];
  filterRemainingList: IFilterOption[];
  translationKeyPrefix: string;
  formName: string;
  onSubmitFilter: (data: any) => void;
  onAddFilter: (selectedFilterName: IFilterOption) => void;
  onRemoveFilter: (index: number) => void;
  onResetFilter: () => void;
  onGetDatasource?: any;
  filterDatasourceOptionList?: IFilterDatasourceOption[];
};

const Filter = ({
  filterOptionList,
  filterSelectedList,
  filterRemainingList,
  translationKeyPrefix,
  formName,
  onSubmitFilter,
  onAddFilter,
  onRemoveFilter,
  onResetFilter,
  onGetDatasource,
  filterDatasourceOptionList,
}: FilterProps) => {
  const [translate] = useTranslation('global');
  const [canAddFilter, setCanAddFilter] = useState<boolean>(
    () => filterSelectedList.length === 0
  );
  const [optionsFilterErrorList, setOptionsFilterErrorList] = useState<
    DatasourceError[]
  >([]);

  const handleOnAddFilter = (e: any) => {
    setCanAddFilter(true);
  };

  const cleanFilterError = (filterName: string) => {
    setOptionsFilterErrorList((prev: DatasourceError[]) =>
      prev.filter((f) => f.filterName !== filterName)
    );
  };

  const handleOnRemove = (index: number, filterName: string) => {
    cleanFilterError(filterName);
    onRemoveFilter(index);
  };

  const handleResetFilter = () => {
    onResetFilter();
    setCanAddFilter(true);
  };

  const getOptionsFromDatasource = async (filter: IFilterOption) => {
    try {
      if (isFunction(filter.getOptions)) {
        const data = await filter.getOptions();
        onGetDatasource(filter, data);
      }

    } catch (error) {
      //seteo error de filtro para mostrarlo
      cleanFilterError(filter.name);
      setOptionsFilterErrorList([
        ...optionsFilterErrorList,
        {
          filterName: filter.name,
          hasError: true,
        },
      ]);
    }
  };

  const filterHasError = (filterName: string) => {
    let obj = optionsFilterErrorList.find(
      (elem) => elem.filterName === filterName
    );
    if (obj !== undefined && obj.hasError === true) {
      return true;
    }
    return false;
  };

  const handleOnChangeSelect = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const filter = filterOptionList.find(
      (filterOption) => filterOption.name === event.target.value
    );
    if (!filter) return;
    if (filter !== undefined) {
      if (filter.getOptions) {
        //Si las opciones del filtro ya fueron recuperadas y guardadas en filterDatasourceOptionList no vuelve a pedir la lista al backend
        //TODO revisar cuando los filtros tengan dependencias entre si porque la lista encontrada podria ser invalida
        const optionDatasource = filterDatasourceOptionList?.find(
          (option: IFilterDatasourceOption) => option.name === filter.name
        );
        if (
          optionDatasource !== undefined &&
          _.isEmpty(optionDatasource.options)
        ) {
          getOptionsFromDatasource(filter);
        }
      }
      setCanAddFilter(false);
      onAddFilter(filter);
    }
  };

  const isButtonDisabled = () =>
    filterSelectedList.length === 0 || !_.isEmpty(optionsFilterErrorList);

  const getComboOptionsList = (filter: IFilterOption) => {
    const comboList = filterDatasourceOptionList?.find(
      (option: IFilterDatasourceOption) => option.name === filter.name
    );
    return comboList?.options?.map((opt: IOptionItem) => {
      const valueTranslated = translate(
        `datasources.${filter.name}.${opt.value}`,
        opt.value
      );
      return { key: opt.key, value: valueTranslated } as unknown as IOptionItem;
    });
  };

  return (
    <>
      <Grid item container spacing={2} alignItems="flex-end">
        {filterSelectedList.map((filter: IFilterOption, index) => (
          <Fragment key={filter.name}>
            <Grid item container lg={4} md={4} xs={12}>
              <SimpleSelect
                key={filter.name}
                disabled
                mandatory
                sx={{ marginTop: '12px' }}
                label={translate('component.filter.labelChooseOption')}
                defaultValue=""
                value={filter.name}
                name="fieldToFilterSelected"
                variant="standard"
              >
                {filterOptionList.map((option: IFilterOption) => {
                  const labelKey = `${translationKeyPrefix}Label_${option.name}`;

                  const translated = translate(labelKey!, option.name);
                  return (
                    <MenuItem key={option.name} value={option.name}>
                      {translated}
                    </MenuItem>
                  );
                })}
              </SimpleSelect>
            </Grid>

            <Grid item container lg={4} md={4} xs={12}>
              {filter.type === FilterOptionTypes.STRING && (
                <TextInputFilter
                  multiple={filter.multiple}
                  name={formName}
                  index={index}
                  label={translate(
                    translationKeyPrefix.concat('Label_').concat(filter.name)
                  )}
                  mandatory
                  variant="standard"
                  fullWidth
                />
              )}
              {filter.type === FilterOptionTypes.CHECKBOX && (
                <CheckboxFilter
                  name={formName}
                  index={index}
                  label={translate(
                    translationKeyPrefix.concat('Label_').concat(filter.name)
                  )}
                  mandatory
                  variant="standard"
                />
              )}
              {filter.type === FilterOptionTypes.DATASOURCE &&
                filter.collection && (
                  <DataSourceSelector
                    renderMode="select"
                    {...filter}
                    name={`${formName}[${index}]`}
                    fullWidth
                    labelKey={filter.name}
                    label={translate(
                      translationKeyPrefix.concat('Label_').concat(filter.name)
                    )}
                    mandatory
                    collection={filter.collection}
                    valueMapper={(value, options = []) => ({
                      ...filter,
                      value,
                      renderValue: filter.multiple
                        ? value.map(
                            (id: string) =>
                              options.find((option) => option.id === id).label
                          )
                        : options.find((option) => option.id === value).label,
                    })}
                    translationKeyPrefix={translationKeyPrefix}
                  />
                )}
              {filter.type === FilterOptionTypes.SELECT &&
                (!filterHasError(filter.name) ? (
                  <SimpleSelectFilter
                    name={formName}
                    variant="standard"
                    index={index}
                    label={translate(
                      translationKeyPrefix.concat('Label_').concat(filter.name)
                    )}
                    mandatory
                    fullWidth
                    options={getComboOptionsList(filter)}
                  />
                ) : (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                      <Typography>
                        {translate('component.filter.datasourceError')}
                      </Typography>
                    </Alert>
                  </Stack>
                ))}
              {filter.type === FilterOptionTypes.MULTISELECT &&
                (!filterHasError(filter.name) ? (
                  <MultiSelectFilter
                    name={`${formName}[${index}]`}
                    label={translate(
                      translationKeyPrefix.concat('Label_').concat(filter.name)
                    )}
                    mandatory
                    variant="standard"
                    fullWidth
                    options={getComboOptionsList(filter)}
                    valueMapper={(value, options = []) => ({
                      ...filter,
                      value,
                      renderValue: value.map(
                        (key: string) =>
                          options.find((option) => option.key === key).value
                      ),
                    })}
                  />
                ) : (
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                      <Typography>
                        {translate('component.filter.datasourceError')}
                      </Typography>
                    </Alert>
                  </Stack>
                ))}
              {filter.type === FilterOptionTypes.DATE_FROM && (
                <DateSelectFilter
                  name={formName}
                  index={index}
                  label={translate(
                    translationKeyPrefix.concat('Label_').concat(filter.name)
                  )}
                  mandatory
                  variant="standard"
                  fullWidth
                  type="from"
                  disableFuture={false}
                />
              )}
              {filter.type === FilterOptionTypes.DATE_TO && (
                <DateSelectFilter
                  name={formName}
                  index={index}
                  label={translate(
                    translationKeyPrefix.concat('Label_').concat(filter.name)
                  )}
                  mandatory
                  variant="standard"
                  fullWidth
                  type="to"
                  disableFuture={false}
                />
              )}
            </Grid>
            <Grid item container lg={2} md={2} xs={12}>
              <Button
                component={Link}
                onClick={() => handleOnRemove(index, filter.name)}
                startIcon={<DeleteOutlineOutlinedIcon />}
              >
                {translate('component.filter.deleteLabelButton')}
              </Button>
            </Grid>
          </Fragment>
        ))}
        {filterOptionList.length !== filterSelectedList.length &&
          !canAddFilter &&
          filterSelectedList.length !== 0 && (
            <Grid item container lg={2} md={2} xs={12} alignItems="flex-end">
              <Button
                component={Link}
                onClick={handleOnAddFilter}
                startIcon={<AddCircleOutlineIcon />}
              >
                {translate('component.filter.buttonAddFilter')}
              </Button>
            </Grid>
          )}
        {(canAddFilter || filterSelectedList.length === 0) && (
          <>
            <Grid item container lg={4} md={4} xs={12}>
              <SimpleSelect
                mandatory
                sx={{ marginTop: '12px' }}
                label={translate('component.filter.labelChooseOption')}
                defaultValue=""
                name="fieldToFilterSelected"
                variant="standard"
                onChange={handleOnChangeSelect}
              >
                {filterRemainingList.map((option: IFilterOption) => {
                  const labelKey = translationKeyPrefix
                    .concat('Label_')
                    .concat(option.name);
                  const translated = translate(labelKey!, option.name);
                  return (
                    <MenuItem key={option.name} value={option.name}>
                      {translated}
                    </MenuItem>
                  );
                })}
              </SimpleSelect>
            </Grid>
            {filterSelectedList.length !== 0 && (
              <Grid item container lg={2} md={4} xs={12}>
                <Button
                  component={Link}
                  onClick={() => setCanAddFilter(false)}
                  startIcon={<DeleteOutlineOutlinedIcon />}
                >
                  {translate('component.filter.deleteLabelButton')}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Box m={3} />
      <Grid container justifyContent="center">
        <Grid item container lg={4} md={8} xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
            <Button
              disabled={isButtonDisabled()}
              onClick={handleResetFilter}
              variant="outlined"
              fullWidth
            >
              {translate('component.filter.buttonCleanSecondaryCTA')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              disabled={isButtonDisabled()}
              variant="contained"
              color="primary"
              onClick={onSubmitFilter}
              fullWidth
            >
              {translate('component.filter.buttonFilterCTA')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box m={1} />
    </>
  );
};

export default Filter;
