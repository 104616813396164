export const tenatization = {
  bind: {
    settings: {
      logo: 'https://banco.bind.com.ar/images/logos-empresas/logo-banco-industrial.png',
    },
    i18n: {
      es: {
        global: {
          menu: {},
        },
      },
    },
    theme: {
      palette: {
        secondary: {},
      },
    },
  },
  macro: {
    settings: {
      logo: 'https://www.macro.com.ar/imagen/macrologoheader2024/logo_macro_2024.png',
    },
    i18n: {
      es: {
        global: {
          menu: {},
        },
      },
    },
    theme: {
      palette: {
        secondary: {},
      },
    },
  },
};

export default {
  tenatization,
};
