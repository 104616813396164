import { TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryOptions, useQuery } from 'react-query';
import type { ITesteable } from '../../common/interfaces';

export interface IMultipleAutoCompleteProps<TOption, TValue>
  extends Omit<
      AutocompleteProps<any, true, any, any, any>,
      'onChange' | 'options' | 'renderInput'
    >,
    ITesteable {
  options?: TOption[];
  queryOptions?: QueryOptions<any[]>;
  label?: string;
  mandatory?: boolean;
  helperText?: string;
  getOptionLabel?: (option: TOption) => string;
  getOptionValue?: (option: TOption) => TValue;
  value: TValue[];
  error?: any;
  onNewOption?: (inputValue: string) => Function | undefined;
  optionsMapper?: (options: TOption[]) => TOption[];
  onChange: (values: TValue[]) => void;
  hideInputLabel?: boolean;
  separator?: string;
  disabled?: boolean;
}

const MultipleAutoComplete = <TOption, TValue>({
  value,
  error,
  onChange,
  options: initialOptions = [],
  optionsMapper,
  queryOptions = {},
  label,
  // @ts-ignore
  getOptionValue = () => null,
  mandatory = false,
  onNewOption,
  helperText,
  hideInputLabel = false,
  separator,
  dataTestId,
  disabled = false,
  ...props
}: IMultipleAutoCompleteProps<TOption, TValue>) => {
  const [translate] = useTranslation('global');
  const inputRef = useRef<any>();

  const { data: rawOptions, isLoading } = useQuery({
    queryKey: [`name:${initialOptions.length}`],
    queryFn: async () => initialOptions,
    ...queryOptions,
  });

  const options = useMemo(() => {
    if (rawOptions && optionsMapper) {
      return optionsMapper(rawOptions);
    }
    return rawOptions;
  }, [rawOptions]);

  const isDisabled = isLoading || (!options?.length && !onNewOption) || disabled;

  const filter = useMemo(() => createFilterOptions<TOption | Function>(), []);

  return (
    <Autocomplete
      {...props}
      data-testid={dataTestId}
      multiple
      disabled={isDisabled}
      options={options ?? []}
      noOptionsText={translate('common.noOptions')}
      renderInput={(params) => (
        <TextField
          ref={inputRef}
          helperText={error?.message || helperText}
          error={!!error}
          label={hideInputLabel ? undefined : mandatory ? `${label}*` : label}
          {...params}
        />
      )}
      filterOptions={(filterOptions, params) => {
        const { inputValue } = params;
        const filtered = filter(filterOptions, params);
        if (!onNewOption) return filtered;
        const fn = onNewOption(inputValue);
        if (fn) filtered.push(fn);
        return filtered;
      }}
      value={(value ?? []).map((optionValue: TValue) => {
        if (!options?.length) return optionValue;
        const find = options?.find(
          (option) => optionValue && JSON.stringify(optionValue) === JSON.stringify(getOptionValue(option))
        );
        return find ?? null;
      })}
      isOptionEqualToValue={(option1, option2) =>
        getOptionValue(option1) === getOptionValue(option2)
      }
      onChange={(_, data: TOption[]) => {
        onChange(data.map(getOptionValue));
      }}
    />
  );
};

export default MultipleAutoComplete;
