import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getDynamicFormDefinitions } from '../../api/dynamicForm/service';
import TitledSection from '../commons/TitledSection';
import FormElement from './FormElement';
import { IFormProps, ITesteable } from '../../common/interfaces';
import {
  FormElementType,
  IFormElement,
} from '../../api/dynamicForm/interfaces';
import Loading from '../commons/Loading';

interface DynamicFormProps extends ITesteable, IFormProps<any> {
  dynamicFormName: string;
  contextData?: any;
  busy?: boolean;
  onSubmit?: (data: any) => void;
  onValid?: (valid: boolean) => void;
  translationPrefix?: string;
  setError?: any;
  setClear?: any;
  clear?: boolean;
  formElementData?: {};
  translateOptions?: string;
}

const buildSchema = ({
  translate,
  formElements,
}: {
  translate: any;
  formElements: IFormElement[];
}) => {
  const shape = {};
  formElements.forEach((formElement) => {
    let yupElement = (() => {
      switch (formElement.formElementType) {
        case FormElementType.MANUAL_DATE_RANGE:
          return yup.string();
        case FormElementType.MANUAL_COMBO:
          if (formElement.multiple) {
            return yup.array().of(yup.string());
          }
          return yup.string();
        default: {
          let element = yup.string();
          // FIXME: Apply pattern matching to MANUAL_DATE_RANGE again
          if (formElement.pattern) {
            element = element.matches(
              new RegExp(formElement.pattern),
              translate('common.invalid')
            );
          }
          return element;
        }
      }
    })();
    if (formElement.required) {
      yupElement = yupElement.required(translate('common.required'));
    }
    Object.assign(shape, {
      [formElement.name]: yupElement,
    });
  });
  return yup.object().shape(shape);
};

const DynamicForm = ({
  dynamicFormName,
  dataTestId = 'DynamicForm',
  contextData,
  onValid,
  onError,
  onSubmit: onSubmitProp,
  formRef,
  translationPrefix,
  setError,
  setClear,
  clear,
  formElementData,
  translateOptions,
}: DynamicFormProps) => {
  const [translate] = useTranslation('global');

  const [schema, setSchema] = useState(
    buildSchema({ translate, formElements: [] })
  );

  const { ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: any) => {
      onSubmitProp?.(data);
    },
    [onSubmitProp]
  );

  const { data: dynamicFormDefinition, isLoading } = useQuery({
    queryKey: [`dynamicForm:${dynamicFormName}`],
    queryFn: async () =>
      dynamicFormName
        ? (
            await getDynamicFormDefinitions({
              dynamicFormName,
              contextData,
              formElementData,
            })
          ).data
        : undefined,
    keepPreviousData: true,
  });

  const formElements = useMemo(
    () =>
      (dynamicFormDefinition?.formElementGroups ?? []).flatMap(
        (formElementGroup) => formElementGroup.formElements
      ),
    [dynamicFormDefinition]
  );

  useEffect(() => {
    if (formElements.length > 0) {
      setSchema(buildSchema({ translate, formElements }));
    }
  }, [translate, formElements]);

  useEffect(() => {
    if (onValid) {
      onValid(methods?.formState?.isValid);
    }
  }, [methods?.formState?.isValid]);

  useImperativeHandle(
    formRef,
    () => ({
      submit: methods.handleSubmit(onSubmit, onError),
    }),
    [methods, onSubmit, onError]
  );

  if (!dynamicFormName) {
    return null;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <FormProvider {...methods}>
      <form>
        <TitledSection
          variant="h1"
          title={translate(`${translationPrefix}.title`, '')}
          dataTestId={dataTestId}
        >
          {dynamicFormDefinition?.formElementGroups.map((formElementGroup) => (
            <TitledSection
              key={formElementGroup.name}
              title={formElementGroup.label}
              spacing={2}
            >
              {formElementGroup.formElements.map((formElement) => (
                <Grid item sm={12} xs={6} key={formElement.label}>
                  <FormElement
                    contextData={contextData}
                    dynamicFormName={dynamicFormName}
                    formElements={formElements}
                    formElement={formElement}
                    translationPrefix={translationPrefix}
                    setError={setError}
                    setClear={setClear}
                    clear={clear}
                    formElementData={formElementData}
                    translateOptions={translateOptions}
                  />
                </Grid>
              ))}
            </TitledSection>
          ))}
        </TitledSection>
      </form>
    </FormProvider>
  );
};

export default DynamicForm;
