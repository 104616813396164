import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';
import { IFormRef } from '../../common/interfaces';
import DynamicForm from '../form/DynamicForm';
import FooterButtons from '../commons/FooterButtons';
import { useUI } from '../../contexts/UIContext';

import CardContainer from './CardContainer';
import { getErrorMessage } from '../../utils/apiUtils';
import { ToastType } from '../commons/Toast';
import { putFPRPendingTask } from '../../api/adminPlatformBPM/service';

interface PendingTaskFormProps {
  dynamicFormName: string;
  taskId: string;
  processInstanceId: string;
  financialProductRequestId: string;
  goBack?: () => void;
}
const dataTestId = 'PendingTaskCard';

const PendingTaskCard = ({
  dynamicFormName,
  processInstanceId,
  taskId,
  financialProductRequestId,
  goBack,
}: PendingTaskFormProps) => {
  const formRef = useRef<IFormRef>();
  const [translate] = useTranslation('global');

  const [busy, setBusy] = useState(false);
  const { setToast } = useUI();

  const contextDataMap = {
    financialProductRequestId,
  };

  const onSubmit = useCallback(
    async (payload: any) => {
      try {
        if (!dynamicFormName) return;
        setBusy(true);

        await putFPRPendingTask(processInstanceId, taskId, {
          loanCreationData: payload,
        });

        setToast({
          message: translate(
            'page.financialProductRequestPendingTask.taskSaveToastSuccessMessage'
          ),
          key: `${dataTestId}_Success`,
        });
        goBack?.();
      } catch (error) {
        const message = getErrorMessage(
          error,
          translate(
            'page.financialProductRequestPendingTask.taskSaveToastErrorMessage'
          )
        );

        setToast({
          type: ToastType.ERROR,
          key: `${dataTestId}_Error`,
          message,
        });
      } finally {
        setBusy(false);
      }
    },
    [
      dynamicFormName,
      processInstanceId,
      taskId,
      financialProductRequestId,
      setToast,
      setBusy,
      goBack,
    ]
  );

  return (
    <CardContainer>
      <DynamicForm
        dynamicFormName={dynamicFormName}
        contextData={contextDataMap}
        formRef={formRef}
        onSubmit={onSubmit}
        translationPrefix={`page.financialProductRequestPendingTask.${dynamicFormName}`}
      />
      <FooterButtons
        buttonConfList={[
          {
            variant: 'outlined',
            dataTestId: `${dataTestId}_CancelButton`,
            onClick: goBack,
            labelKey: 'common.cancel',
          },
          {
            labelKey: 'common.confirm',
            dataTestId: `${dataTestId}_SubmitButton`,
            loading: busy,
            onClick: () => formRef?.current?.submit(),
          },
        ]}
      />
    </CardContainer>
  );
};

export default PendingTaskCard;
