import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { styled } from '@mui/system';
import { TextInput } from 'vulpino';
import { Grid } from '@mui/material';
import { IIndexedProcessVariable } from '../../../api/adminFinancialProductRequest/interfaces';

import FilterContainer from '../../commons/FilterContainer';
import useRoutePage from '../../../hooks/useRoutePage';
import { RouteKey } from '../../../navigation/constants';
import {
  IFilterAppliedOption,
  IFilterDatasourceOption,
  IFilterOption,
} from '../../../common/interfaces';
import useFiltersProps from '../../../hooks/useFiltersProps';
import { getAllFinancialProductRequestProcessVariables } from '../../../api/adminFinancialProductRequest/service';
import CustomDataGrid from '../../commons/CustomDataGrid';
import { ProcessVariableType } from '../../../api/adminFinancialProductRequest/enums';

import useFilterOptions from './useFilterOptions';

const StyledPre = styled('div')(() => ({
  margin: 0,
  whiteSpace: 'pre-wrap',
}));

type ProcessVariablesCardProps = {
  processInstanceId: string;
};

type FilterType = {
  [key in RouteKey]: string;
};

const activeSection = 'processVariables';

interface IRouteState {
  pageNumber: number;
  pageSize: number;
  filterAppliedList: IFilterAppliedOption[];
  filterDatasourceOptionList: IFilterDatasourceOption[];
}

const ProcessVariablesCard = ({
  processInstanceId,
}: ProcessVariablesCardProps) => {
  const [translate] = useTranslation('global');

  const { routeState, mergeRouteState } = useRoutePage<IRouteState>({
    routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
    initialState: {
      pageNumber: 1,
      pageSize: 100,
      filterAppliedList: [],
      filterDatasourceOptionList: [],
    },
  });

  const {
    pageNumber,
    pageSize,
    filterAppliedList,
    filterDatasourceOptionList,
  } = routeState;

  const { filters, ...filterProps } = useFiltersProps<IFilterOption>({
    appliedFilters: filterAppliedList,
    onChange: (appliedFilters) =>
      mergeRouteState({
        filterAppliedList: appliedFilters,
      }),
  });

  const queryResult = useQuery({
    queryKey: [`financialProductRequestProcessVariables:${processInstanceId}`],
    queryFn: async () =>
      (await getAllFinancialProductRequestProcessVariables(processInstanceId))
        .data,
    keepPreviousData: true,
  });

  const { data: queryData } = queryResult;

  const columns: GridColDef<IIndexedProcessVariable>[] = useMemo(
    () => [
      {
        field: 'creationDate',
        width: 200,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.processVariables.columns.creationDate'
        ),
        renderCell: ({ row }) =>
          row.creationDate
            ? dayjs(row.creationDate).format(translate('date.timestamp'))
            : '',
      },
      {
        field: 'name',
        flex: 1,
        sortable: false,
        headerName: translate(
          'page.financialProductRequest.processVariables.columns.name'
        ),
      },
    ],
    [translate]
  );

  const filterOptionList = useFilterOptions();

  const [rows, setRows] = useState<IIndexedProcessVariable[] | undefined>([]);

  // Computed
  const computed = useMemo(() => queryData?.result, [queryData]);

  useEffect(() => {
    if (computed) {
      setRows(computed);
    }
  }, [computed]);

  const rowCount = useMemo(() => queryData?.totalItems ?? 0, [queryData]);

  const handleOnPageChange = (newPage: number) =>
    mergeRouteState({
      pageNumber: newPage,
    });

  const handleOnPageSizeChange = (newPageSize: number) =>
    mergeRouteState({
      pageSize: newPageSize,
    });

  const handleChange = (val: string) => {
    setRows(
      computed?.filter((row: IIndexedProcessVariable) => {
        switch (row.type) {
          case ProcessVariableType.NULL:
            return row.name.toLowerCase().includes(val.toLowerCase());
          case ProcessVariableType.OBJECT:
            return (
              row.name.toLowerCase().includes(val.toLowerCase()) ||
              JSON.stringify(row.value, null, 2)
                .toLowerCase()
                .includes(val.toLowerCase())
            );
          default:
            return (
              row.name.toLowerCase().includes(val.toLowerCase()) ||
              row.value.toString().toLowerCase().includes(val.toLowerCase())
            );
        }
      })
    );
  };

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(([_, value]) => handleChange(value));
    } else {
      setRows(computed);
    }
  }, [filters]);

  return (
    <FilterContainer
      filterOptionList={filterOptionList ?? []}
      filterDatasourceOptionList={filterDatasourceOptionList}
      translationKeyPrefix="page.financialProductRequest.filter"
      title={translate(
        `page.financialProductRequest.${activeSection}.title`,
        ''
      )}
      {...filterProps}
    >
      <CustomDataGrid
        getRowId={(row) => row.index}
        rows={rows ?? []}
        columns={columns}
        rowCount={rowCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
        showFirstButton
        showLastButton
        disableSelectionOnClick={false}
        rowsPerPageOptions={[10, 30, 50, 100]}
        onPageChange={handleOnPageChange}
        onPageSizeChange={handleOnPageSizeChange}
        translationKeyPrefix="page.financialProductRequest.chip"
        previewColumn={{
          headerName: translate(
            'page.financialProductRequest.processVariables.columns.value'
          ),
          render: (row) =>
            row && (
              <StyledPre>
                {(() => {
                  switch (row.type) {
                    case ProcessVariableType.OBJECT:
                      return JSON.stringify(row.value, null, 2);
                    default:
                      return row.value;
                  }
                })()}
              </StyledPre>
            ),
        }}
      />
    </FilterContainer>
  );
};

export default ProcessVariablesCard;
