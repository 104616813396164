import Keycloak from 'keycloak-js';
import { env } from './api/env';
import { getTenantName } from './utils/core';
import { getUserPermissions } from './api/adminUsersRoles/service';

const keycloak = new Keycloak({
  url: env.KEYCLOAK_BASE_URL.replaceAll(/\${tenant}/g, getTenantName()),
  realm: env.KEYCLOAK_REALM.replaceAll(/\${tenant}/g, getTenantName()),
  clientId: env.KEYCLOAK_CLIENT_ID,
});

export const checkLogin = async (onAuthenticatedCallback) => {
  const APP_ADMIN_ONSCORE_ROLE_NAME = 'APP_ADMIN';
  const ADMIN_MACRO_ROLE_NAME = 'Admin Macro';
  const ADMIN_COMERCIO_ROLE_NAME = 'Admin Comercio';
  const BACK_OFFICE_MACRO_ROLE_NAME = 'Back Office Macro';
  const REFERENTE_DIVISION_ROLE_NAME = 'Referente división';

  try {
    const authenticated = await keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
      pkceMethod: 'S256',
    });
    if (authenticated) {
      let loggedInUserRoles = (await getUserPermissions()).data.roles;
      // FIXME: This piece of code should not depend on a tenant or a tenant's roles
      if (
        getTenantName() === 'macro' &&
        !loggedInUserRoles.includes(APP_ADMIN_ONSCORE_ROLE_NAME) &&
        !loggedInUserRoles.includes(ADMIN_MACRO_ROLE_NAME) &&
        !loggedInUserRoles.includes(ADMIN_COMERCIO_ROLE_NAME) &&
        !loggedInUserRoles.includes(BACK_OFFICE_MACRO_ROLE_NAME) &&
        !loggedInUserRoles.includes(REFERENTE_DIVISION_ROLE_NAME)
      ) {
        await keycloak.logout();
      }
      onAuthenticatedCallback();
    }
  } catch (err) {
    console.log(err);
  }
};

export default keycloak;
