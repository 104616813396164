import CodeMappingEditPage from '../pages/CodeMappingEditPage';
import CommerceViewPage from '../pages/CommerceViewPage';
import CommerceBusinessSchemaViewPage from '../pages/CommerceBusinessSchemaViewPage';
import CodeMappingViewPage from '../pages/CodeMappingViewPage';
import CatalogItemViewPage from '../pages/CatalogItemViewPage';
import PendingTasksPage from '../pages/FPRPendingTasksPage';
import FPRPendingTaskViewPage from '../pages/FPRPendingTaskViewPage';
import DynamicFormsPage from '../pages/DynamicFormsPage';
import DynamicFormViewPage from '../pages/DynamicFormViewPage';
import UsersPage from '../pages/UsersPage';
import UserEditPage from '../pages/UserEditPage';
import UserViewPage from '../pages/UserViewPage';
import RolesPage from '../pages/RolesPage';
import RoleEditPage from '../pages/RoleEditPage';
import RoleViewPage from '../pages/RoleViewPage';
import CommercesPage from '../pages/CommercesPage';
import CommerceEditPage from '../pages/CommerceEditPage';
import FinancialProductRequestsPage from '../pages/FinancialProductRequestsPage';
import FinancialProductRequestViewPage from '../pages/FinancialProductRequestViewPage';
import FinancialProductRequestRejectPage from '../pages/FinancialProductRequestRejectPage';
import CatalogItemsPage from '../pages/CatalogItemsPage';
import CodeMappingsPage from '../pages/CodeMappingsPage';
import CatalogItemEditPage from '../pages/CatalogItemEditPage';
import { RouteKey } from './constants';
import { Permissions } from '../common/enums';
import { FilterPage, GeneratePage, ReportPage } from '../pages/Reports';
import {
  CalendarForm,
  CalendarPage,
  CalendarView,
  EventForm,
  EventView,
} from '../pages/Calendar';
import CommerceBusinessSchemas from '../components/pagePartial/Commerces/CommerceBusinessSchemas';
import BusinessSchemaEditPage from '../pages/BusinessSchemaEditPage';
import CommerceBusinessSchemaQuotaAddPage from '../pages/CommerceBusinessSchemaQuotaAddPage';
import { RatesGroup, RatesPage } from '../pages/Rates';
import { RatesGroupDetail } from '../pages/Rates/RatesGroupDetail';
import { FinancialOffices } from '../pages/FinancialOfficesPage';
import FinancialOfficeEdit from '../pages/FinancialOfficesPage/FinancialOfficeEdit';
import FinancialOfficeDetail from '../pages/FinancialOfficesPage/FinancialOfficeDetail/FinancialOfficeDetail';
import FinancialOfficeDivisionEdit from '../pages/FinancialOfficesPage/FinancialOfficeDivisionEdit/FinancialOfficeDivisionEdit';
import FinancialOfficeDivisionDetail from '../pages/FinancialOfficesPage/FinancialOfficeDivisionDetail';

export const protectedNavTree = [
  {
    routeKey: RouteKey.FPR_PENDING_TASKS,
    Component: PendingTasksPage,
    permissions: Permissions.FPR_PENDING_TASK_READ,
    routes: [
      {
        routeKey: RouteKey.FPR_PENDING_TASKS_VIEW,
        Component: FPRPendingTaskViewPage,
        permissions: Permissions.FPR_PENDING_TASK_READ,
      },
      {
        routeKey: RouteKey.FPR_PENDING_TASKS_FINANCIAL_PRODUCTS_REQUESTS_REJECT,
        Component: FinancialProductRequestRejectPage,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_UPDATE,
      },
    ],
  },
  {
    routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS,
    Component: FinancialProductRequestsPage,
    permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
    routes: [
      {
        routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS_REJECT,
        Component: FinancialProductRequestRejectPage,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_UPDATE,
      },
      {
        routeKey: RouteKey.FINANCIAL_PRODUCTS_REQUESTS_VIEW,
        Component: FinancialProductRequestViewPage,
        permissions: Permissions.FINANCIAL_PRODUCT_REQUEST_READ,
      },
    ],
  },
  {
    routeKey: RouteKey.REPORTS,
    Component: ReportPage,
    permissions: Permissions.REPORT_EXECUTION_READ,
    routes: [
      {
        routeKey: RouteKey.REPORTS_FILTERS,
        Component: FilterPage,
        permissions: Permissions.REPORT_EXECUTION_CREATE,
      },
      {
        routeKey: RouteKey.REPORTS_GENERATE,
        Component: GeneratePage,
        permissions: Permissions.REPORT_EXECUTION_CREATE,
      },
    ],
  },
  {
    routeKey: RouteKey.RATES,
    Component: RatesPage,
    permissions: [
      Permissions.INTEREST_RATE_READ,
      Permissions.INTEREST_RATE_CREATE,
      Permissions.INTEREST_RATE_UPDATE,
    ],
    routes: [
      {
        routeKey: RouteKey.VIEW_RATES,
        Component: RatesGroup,
        permissions: [
          Permissions.INTEREST_RATE_READ,
          Permissions.INTEREST_RATE_CREATE,
          Permissions.INTEREST_RATE_UPDATE,
        ],
      },
      {
        routeKey: RouteKey.VIEW_RATE_GROUP,
        Component: RatesGroupDetail,
        permissions: [
          Permissions.INTEREST_RATE_READ,
          Permissions.INTEREST_RATE_CREATE,
          Permissions.INTEREST_RATE_UPDATE,
        ],
      },
    ],
  },
  {
    routeKey: RouteKey.FINANCIAL_OFFICES,
    Component: FinancialOffices,
    permissions: [Permissions.FRONTEND_FINANCIAL_OFFICE_READ],
    routes: [
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_NEW,
        Component: FinancialOfficeEdit,
        permissions: [Permissions.FINANCIAL_OFFICE_CREATE],
      },
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_VIEW,
        Component: FinancialOfficeDetail,
        permissions: [Permissions.FINANCIAL_OFFICE_READ],
      },
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_EDIT,
        Component: FinancialOfficeEdit,
        permissions: [Permissions.FINANCIAL_OFFICE_UPDATE],
      },
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_DIVISION_NEW,
        Component: FinancialOfficeDivisionEdit,
        permissions: [Permissions.DIVISION_CREATE],
      },
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_DIVISION_VIEW,
        Component: FinancialOfficeDivisionDetail,
        permissions: [Permissions.DIVISION_READ],
      },
      {
        routeKey: RouteKey.FINANCIAL_OFFICE_DIVISION_EDIT,
        Component: FinancialOfficeDivisionEdit,
        permissions: [Permissions.DIVISION_UPDATE],
      },
    ],
  },
  {
    routeKey: RouteKey.CALENDAR,
    Component: CalendarPage,
    permissions: [
      Permissions.CALENDAR_READ,
      Permissions.CALENDAR_CREATE,
      Permissions.CALENDAR_UPDATE,
      Permissions.CALENDAR_DELETE,
    ],
    routes: [
      {
        routeKey: RouteKey.CREATE_CALENDAR,
        Component: CalendarForm,
        permissions: Permissions.CALENDAR_CREATE,
      },
      {
        routeKey: RouteKey.VIEW_CALENDAR,
        Component: CalendarView,
        permissions: [
          Permissions.CALENDAR_READ,
          Permissions.CALENDAR_CREATE,
          Permissions.CALENDAR_UPDATE,
          Permissions.CALENDAR_DELETE,
        ],
      },
      {
        routeKey: RouteKey.EDIT_CALENDAR,
        Component: CalendarForm,
        permissions: [Permissions.CALENDAR_CREATE, Permissions.CALENDAR_UPDATE],
      },
      {
        routeKey: RouteKey.CREATE_EVENT,
        Component: EventForm,
        permissions: [Permissions.CALENDAR_CREATE, Permissions.CALENDAR_UPDATE],
      },
      {
        routeKey: RouteKey.VIEW_EVENT,
        Component: EventView,
        permissions: [
          Permissions.CALENDAR_READ,
          Permissions.CALENDAR_CREATE,
          Permissions.CALENDAR_UPDATE,
          Permissions.CALENDAR_DELETE,
        ],
      },
      {
        routeKey: RouteKey.EDIT_EVENT,
        Component: EventForm,
        permissions: [Permissions.CALENDAR_CREATE, Permissions.CALENDAR_UPDATE],
      },
    ],
  },
  {
    routeKey: RouteKey.ROLES,
    Component: RolesPage,
    permissions: Permissions.ROLE_READ,
    routes: [
      {
        routeKey: RouteKey.ROLES_NEW,
        Component: RoleEditPage,
        permissions: Permissions.ROLE_CREATE,
      },
      {
        routeKey: RouteKey.ROLES_EDIT,
        Component: RoleEditPage,
        permissions: Permissions.ROLE_UPDATE,
      },
      {
        routeKey: RouteKey.ROLES_VIEW,
        Component: RoleViewPage,
        permissions: Permissions.ROLE_READ,
      },
    ],
  },
  {
    routeKey: RouteKey.USERS,
    Component: UsersPage,
    permissions: Permissions.USER_READ,
    routes: [
      {
        routeKey: RouteKey.USERS_NEW,
        Component: UserEditPage,
        permissions: Permissions.USER_CREATE,
      },
      {
        routeKey: RouteKey.USERS_EDIT,
        Component: UserEditPage,
        permissions: Permissions.USER_UPDATE,
      },
      {
        routeKey: RouteKey.USERS_VIEW,
        Component: UserViewPage,
        permissions: Permissions.USER_READ,
      },
    ],
  },
  {
    routeKey: RouteKey.COMMERCES,
    Component: CommercesPage,
    permissions: Permissions.COMMERCE_READ,
    routes: [
      {
        routeKey: RouteKey.COMMERCES_NEW,
        Component: CommerceEditPage,
        permissions: Permissions.COMMERCE_CREATE,
      },
      {
        routeKey: RouteKey.COMMERCES_EDIT,
        Component: CommerceEditPage,
        permissions: Permissions.COMMERCE_UPDATE,
      },
      {
        routeKey: RouteKey.COMMERCES_VIEW,
        Component: CommerceViewPage,
        permissions: Permissions.COMMERCE_READ,
      },
      {
        routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA,
        Component: CommerceBusinessSchemas,
        permissions: Permissions.COMMERCE_READ,
      },
      {
        routeKey: RouteKey.COMMERCES_BUSINESS_SCHEMA_NEW,
        Component: BusinessSchemaEditPage,
        permissions: Permissions.COMMERCE_READ,
      },
      {
        routeKey: RouteKey.COMMERCES_BUSINESS_SCHEMA_EDIT,
        Component: BusinessSchemaEditPage,
        permissions: Permissions.COMMERCE_READ,
      },
      {
        routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA_VIEW,
        Component: CommerceBusinessSchemaViewPage,
        permissions: Permissions.COMMERCE_READ,
      },
      {
        routeKey: RouteKey.COMMERCE_BUSINESS_SCHEMA_QUOTA_NEW,
        Component: CommerceBusinessSchemaQuotaAddPage,
        permissions: Permissions.COMMERCE_UPDATE,
      },
    ],
  },
  {
    routeKey: RouteKey.CATALOG_ITEMS,
    Component: CatalogItemsPage,
    permissions: [Permissions.CATALOG_READ],
    routes: [
      {
        routeKey: RouteKey.CATALOG_ITEM_NEW,
        Component: CatalogItemEditPage,
        permissions: Permissions.CATALOG_CREATE,
      },
      {
        routeKey: RouteKey.CATALOG_ITEM_EDIT,
        Component: CatalogItemEditPage,
        permissions: Permissions.CATALOG_UPDATE,
      },
      {
        routeKey: RouteKey.CATALOG_ITEM_VIEW,
        Component: CatalogItemViewPage,
        permissions: Permissions.CATALOG_CREATE,
      },
    ],
  },
  {
    routeKey: RouteKey.CODE_MAPPINGS,
    Component: CodeMappingsPage,
    permissions: Permissions.FRONTEND_CODE_MAPPING_READ,
    routes: [
      {
        routeKey: RouteKey.CODE_MAPPING_NEW,
        Component: CodeMappingEditPage,
        permissions: Permissions.CODE_MAPPING_CREATE,
      },
      {
        routeKey: RouteKey.CODE_MAPPING_EDIT,
        Component: CodeMappingEditPage,
        permissions: Permissions.CODE_MAPPING_UPDATE,
      },
      {
        routeKey: RouteKey.CODE_MAPPING_VIEW,
        Component: CodeMappingViewPage,
        permissions: Permissions.CODE_MAPPING_READ,
      },
    ],
  },
  {
    routeKey: RouteKey.DYNAMIC_FORMS,
    Component: DynamicFormsPage,
    permissions: Permissions.SKIP_PERMISSIONS,
    routes: [
      {
        routeKey: RouteKey.DYNAMIC_FORMS_VIEW,
        Component: DynamicFormViewPage,
        permissions: Permissions.SKIP_PERMISSIONS,
      },
    ],
  },
];
