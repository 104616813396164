import type {
  IGetListResponse,
  IPaginatedParams,
  IRoleFiltersState,
  IRoleFormData,
  IUserFiltersState,
  IUserFormData,
} from '../../common/interfaces';
import type { IPermissionData, IRoleData, IUserData } from './interfaces';
import fetch from '../api';
import { env } from '../env';

interface IGetPermissionsListParams extends IPaginatedParams {}

interface IGetRolesListParams extends IPaginatedParams {
  filters?: IRoleFiltersState;
}

interface IGetUsersListParams extends IPaginatedParams {
  filters?: IUserFiltersState;
}

export const getUserPermissions = () =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: '/v1/profiles/me',
    method: 'get',
  });

export const getUsersList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters,
}: IGetUsersListParams = {}): Promise<IGetListResponse<IUserData>> => {
   const newFilters = { ...filters, userType:'PLATFORM'};
      return fetch({
        baseURL: env.APIS_ONSCORE,
        url: `/v1/users`,
        method: 'get',
        params: {
          sort: 'userName',
          pageNumber,
          pageSize,
          ...newFilters,
        },
  });
};
export const getUser = (userId: string) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/users/${userId}`,
    method: 'get',
  });

export const createUser = (postData: IUserFormData) => {
  const postDataAddUserType = {...postData, userType:'PLATFORM'};
  return fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/macro/authentication/users`,
    method: 'post',
    data: postDataAddUserType,
  });
};
  

export const updateUser = (userId: string, postData: IUserFormData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/users/${userId}`,
    method: 'put',
    data: postData,
  });

export const getRolesList = ({
  pageNumber = 1,
  pageSize = 1000,
  filters = {},
}: IGetRolesListParams = {}): Promise<IGetListResponse<IRoleData>> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/roles`,
    method: 'get',
    params: {
      sort: 'roleName',
      pageNumber,
      pageSize,
      ...filters,
    },
  });

export const getUserAssignableRolesList = async () => {
  let allRoles = (await getRolesList()).data.result
  let metadataAssignableRoles = (await getUserPermissions()).data?.metadata.ROLES_ALLOWED_TO_ASSIGN
  if(metadataAssignableRoles){
    return allRoles.filter(role => metadataAssignableRoles.includes(role.id))
  } 
  return allRoles
}

export const getRole = (roleId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/roles/${roleId}`,
    method: 'get',
  });

export const createRole = (postData: IRoleFormData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/roles`,
    method: 'post',
    data: postData,
  });

export const deleteRole = (roleId: number) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/roles/${roleId}`,
    method: 'delete',
  });

export const updateRole = (roleId: number, postData: IRoleFormData) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/roles/${roleId}`,
    method: 'put',
    data: postData,
  });

export const getItemList = (url: string, filterParams: any) =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `${url}`,
    method: 'get',
    params: filterParams,
  });

export const getPermissionsList = ({
  pageNumber = 1,
  pageSize = 1000,
}: IGetPermissionsListParams = {}): Promise<
  IGetListResponse<IPermissionData>
> =>
  fetch({
    baseURL: env.APIS_ONSCORE,
    url: `/v1/permissions`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
    },
  });
