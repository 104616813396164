import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import {
  CloseOutlined,
  DownloadRounded,
  PermMedia,
  VisibilityOutlined,
} from '@mui/icons-material';
import fileDownload from 'js-file-download';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  GridProps,
  Typography,
} from '@mui/material';
import { styled, Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { getFinancialProductRequestDocumentFileBlob } from '../../api/adminFinancialProductRequest/service';
import { DocumentStatus } from '../../common/enums';
import { IDocumentFileWithDocument } from '../../common/interfaces';
import StatusBadge, { Status } from '../commons/StatusBadge';

interface DocumentFileContainerProps extends GridProps {
  file: IDocumentFileWithDocument;
  requestId: string;
}

const ThumbCardMedia = styled(CardMedia)(({ theme }) => ({
  height: theme.spacing(24),
  margin: theme.spacing(2),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledBox = styled(Box)(() => ({
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: 'calc(100vh - 160px)',
}));

const StyledContainer = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const NoShadowCard = styled(Grid)(({ theme }) => ({
  boxShadow: 'none',
}));

const bottomBordered = (theme: Theme) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
});

const iconSx = (theme: Theme) => ({
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.grey[100],
  borderRadius: '50%',
  padding: 1,
  height: theme.spacing(5),
  width: theme.spacing(5),
  margin: theme.spacing(0.5),
  cursor: 'pointer',
});

const statusMapping = {
  [DocumentStatus.VERIFIED]: {
    status: Status.SUCCESS,
    labelKey: 'common.verified',
  },
  [DocumentStatus.NOT_VERIFIED]: {
    status: Status.WARNING,
    labelKey: 'common.not_verified',
  },
  [DocumentStatus.REJECTED]: {
    status: Status.FAILURE,
    labelKey: 'common.rejected',
  },
};

const DocumentFileContainer = ({
  requestId,
  file,
  ...props
}: DocumentFileContainerProps) => {
  const [translate] = useTranslation('global');
  const [previewBlob, setPreviewBlob] = useState<Blob>();
  const [previewSrc, setPreviewSrc] = useState<string>();
  const [previewDialog, setPreviewDialog] = useState(false);
  const isImage = file.contentType.match('image') !== null;
  const queryClient = useQueryClient();

  const createdDate = useMemo(
    () => dayjs(file.document.createdDate).format(translate('date.short')),
    [translate, file.document.createdDate]
  );

  const getFileBlob = useCallback(
    () =>
      queryClient.fetchQuery(
        [`getFinancialProductRequestDocumentFile:${file.id}`],
        async () =>
          (
            await getFinancialProductRequestDocumentFileBlob(
              requestId,
              file.document.documentProviderId,
              file.id
            )
          ).data
      ),
    [file]
  );

  useEffect(() => {
    (async () => {
      // Non-image documents preview are currently not supported
      if (!isImage) return;
      setPreviewBlob(await getFileBlob());
    })();
  }, [isImage, file]);

  const statusProps = useMemo(() => {
    const mapped = statusMapping[file.document.status];
    return {
      status: mapped.status,
      label: translate(mapped.labelKey),
    };
  }, [file.document.status, translate]);

  useEffect(() => {
    if (!previewBlob) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') setPreviewSrc(reader.result);
    };
    reader.readAsDataURL(previewBlob);
  }, [isImage, previewBlob]);

  const downloadFile = useCallback(async () => {
    const blob = await getFileBlob();
    fileDownload(blob, file.fileName);
  }, [file, previewBlob]);

  const previewFile = useCallback(() => {
    setPreviewDialog(true);
  }, [file, previewBlob]);

  const createdDateText = (
    <Typography fontSize="small">
      {translate('common.uploadedAt', { date: createdDate })}
    </Typography>
  );

  return (
    <Grid item sm={12} md={4} lg={3} {...props}>
      <StyledContainer>
        <Box sx={bottomBordered}>
          <ThumbCardMedia image={previewSrc}>
            {!previewSrc && (
              <PermMedia sx={{ fontSize: '100px' }} color="disabled" />
            )}
          </ThumbCardMedia>
        </Box>
        <CardContent>
          <Grid container>
            <Grid item flexGrow={1}>
              <Typography fontSize="small" fontWeight="bold">
                {translate(
                  `page.financialProductRequest.documentKey.${file.document.key}`,
                  file.document.key
                )}
              </Typography>
              {createdDateText}
              <StatusBadge {...statusProps} sx={{ mt: 1 }} />
            </Grid>
            <Grid item>
              {isImage && (
                <VisibilityOutlined sx={iconSx} onClick={previewFile} />
              )}
              <DownloadRounded sx={iconSx} onClick={downloadFile} />
            </Grid>
          </Grid>
        </CardContent>
      </StyledContainer>
      <Dialog
        open={previewDialog}
        onClose={() => setPreviewDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={bottomBordered}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item flexGrow={1}>
              <Typography fontWeight="bold">{file.fileName}</Typography>
              {createdDateText}
            </Grid>
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              sx={{ display: 'flex' }}
              flexShrink={1}
              flexGrow={0}
            >
              <CloseOutlined
                fontSize="large"
                onClick={() => setPreviewDialog(false)}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <NoShadowCard>
            <StyledBox
              sx={{
                backgroundImage: `url(${previewSrc})`,
              }}
            />
          </NoShadowCard>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default DocumentFileContainer;
