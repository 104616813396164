import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useDataSources, {
  DataSourceCollection,
} from '../../hooks/useDataSources';
import useTranslate from '../../hooks/useTranslate';
import SimpleSelect from '../commons/SimpleSelect';
import MultiSelect from '../commons/MultiSelect';
import type { ITesteable } from '../../common/interfaces';

import AutoCompleteSelector from './AutoCompleteSelector';
import type { IFormField } from './FormFields';

export interface DataSourceSelectorProps
  extends Omit<IFormField, 'type'>,
    ITesteable {
  label?: string;
  labelKey?: string;
  fullWidth?: boolean;
  collection: DataSourceCollection;
  multiple?: boolean;
  renderMode?: 'autocomplete' | 'select';
  valueMapper?: (value: any, options: any[]) => any;
  optionsMapper?: (options: any[]) => any[];
  translationKeyPrefix?: string;
  translationKeyPrefixSimple?: string;
  selectorFullWidth?: boolean;
  disabled?: boolean;
  setRefOptions?: Function;
  }

export interface IDataSourceOptionItem {
  id: string;
  label: string;
}

const DataSourceSelector = ({
  label: labelProp,
  labelKey,
  collection,
  mandatory = false,
  multiple = false,
  renderMode = 'autocomplete',
  valueMapper,
  translationKeyPrefix,
  translationKeyPrefixSimple,
  selectorFullWidth = false,
  disabled = false,
  setRefOptions,
  ...props
}: DataSourceSelectorProps) => {
  const { getOptions } = useDataSources();
  const [options, setOptions] = useState<IDataSourceOptionItem[]>([]);
  const { control } = useFormContext();
  const [t] = useTranslation('global');

  const label = useTranslate(labelKey, labelProp ?? '', {
    mandatory,
  });

  useEffect(() => {
    (async () => {
      const data = await getOptions({
        collection,
      });
      data.forEach((item) => {
        item.label = translationKeyPrefixSimple ?
        t(`${translationKeyPrefixSimple}.${item.id}`)
          : t(`${translationKeyPrefix}Label_${labelKey}_${item.id}`,
              item.label)
      });
      setOptions(data);
      setRefOptions?.(data);
    })();
    }, [getOptions]);

      if (renderMode === 'select' && !multiple && options) {
    return (
      <Controller
        {...props}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <SimpleSelect
            sx={{ marginTop: '12px' }}
            label={label}
            name="fieldToFilterSelected"
            defaultValue=""
            value={
              Array.isArray(field.value?.value)
                ? field.value?.value[0] ?? ''
                : field.value?.value ?? ''
            }
            error={!!error}
            variant="standard"
            onChange={(ev) => {
                            field.onChange(
                valueMapper
                  ? valueMapper(ev.target.value, options)
                  : [ev.target.value]
              );
            }}
          >
            {options?.map((opt: IDataSourceOptionItem) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.label}
              </MenuItem>
            ))}
          </SimpleSelect>
        )}
      />
    );
  }

  if (renderMode === 'select' && multiple) {
    return (
      <Controller
        {...props}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MultiSelect
            label={label}
            value={field.value?.value ?? []}
            onChange={(ev) => {
                            if (!ev.target.value) return;
              field.onChange(
                valueMapper
                  ? valueMapper(ev.target.value, options)
                  : ev.target.value
              );
            }}
            error={!!error}
            options={options}
            fullWidth
          />
        )}
      />
    );
  }

  return (
    <AutoCompleteSelector<IDataSourceOptionItem, string>
      {...props}
      label={label}
      options={options}
      multiple={multiple}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.label}
      selectorFullWidth={selectorFullWidth}
      defaultValue={options?.length === 1 ? options[0]?.id : ''}
      disabled={disabled}
    />
  );
};

export default DataSourceSelector;
